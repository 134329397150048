<div class="navigation-wrap">

  <a routerLink="/dashboard" *ngIf="!isCanadaUser && !isDDUser && !isThreeContractNo" rel="noopener noreferrer">
    <img class="logo" src="../../../../assets/images/logos/ca_us/InsureShield_Logo_226x79_larger_tagline-01.png"
      alt="{{ 'billing.method.altUps' | translate }}">
  </a>
  <a routerLink="/dashboard" *ngIf="isThreeContractNo" rel="noopener noreferrer">
    <img class="logo" src="../../../../assets/images/logos/us_eu/Europe_Logo.png"
      alt="{{ 'billing.method.altUps' | translate }}">
  </a>
  <a routerLink="/dashboard" *ngIf="isDDUser" rel="noopener noreferrer">
    <img class="logo" src="../../../../../assets/images/logos/DeliveryDefense_horizontal_USPC.png"
      alt="{{ 'billing.method.altUps' | translate }}">
  </a>
  <a routerLink="/dashboard" *ngIf="isCanadaUser" rel="noopener noreferrer">
    <img class="logo" src="../../../../assets/images/logos/ca_us/UPSC_InsureShield_Horizontal CANADA_226x79_72dpi.png"
      alt="{{ 'billing.method.altUps' | translate }}">
  </a>
  <ul class="navigation-items" [hidden]="!showRLSNav">
    <li class="navigation-item-short" *ngIf="!isAdjuster && !isUspsUser && !isDDUser && (!isGuestClaim || isHPGuestUser)">
      <a href="#" routerLink="/dashboard" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"
        (click)="tagging('dashboard')"><span class="new-question">
          <span class="txt">{{'app.navigation.dashboard' | translate }}</span></span>
      </a>
    </li>

    <li class="navigation-item-short" *ngIf="!isAdjuster && !isUspsUser && !isDDUser && !isThreeContractNo && (!isGuestClaim || isHPGuestUser)">
      <a href="#" routerLink="/policies" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"
        (click)="tagging('policies')"><span class="new-question">
          <span class="txt">{{
            'app.navigation.policies' | translate }}</span>
        </span>
      </a>
    </li>
    <li class="navigation-item-short"
      *ngIf="((userPermission.isClaimView) || (userPermission.isFileClaim)) && !isAdjuster && !isUspsUser && (!isDDUser || isDDClaimsFlow) && (!isGuestClaim || isHPGuestUser)">
      <span class="h-100">
        <a href="#" routerLink="/claims" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"
          (click)="tagging('claims')">
          <span class="new-question">
            <span class="txt">{{'app.navigation.claims' | translate }}</span>
            <span *ngIf="showStats && (userPermission.isFileClaim)" (click)="gotoMessages($event)" class="message-badge"
              #tooltip="matTooltip" [matTooltip]="messageCnt" [matTooltipPosition]="position.value!"
              [matTooltipHideDelay]="5000">
              {{ claimStats?.claimsWithNewMessagesCnt }}
            </span>
          </span>
        </a>
      </span>
    </li>

    <li class="navigation-item-short"
      *ngIf="((userPermission.isBillingView) && (!isAdjuster) && (!isGuestClaim) && (showBilling)) && !isThreeContractNo">
      <a href="#" routerLink="/billing" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"
        (click)="tagging('billing')">
        <span class="new-question">
          <span class="txt" *ngIf="!(isUsaUser && billingWallet)">{{ 'app.navigation.billing' | translate }}</span>
          <span class="txt" *ngIf="(isUsaUser && billingWallet)">{{ 'app.navigation.billingWallet' | translate }}</span>
        </span>
      </a>
    </li>
    <li class="navigation-item-short" *ngIf="isGuestUser && !isUspsUser && !isDDUser">
      <a href="#" routerLink="/claims/new" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <span class="new-question">
          <span class="txt">{{ 'claims.claimMenus.fileNewClaim' | translate }}</span></span>
      </a>
    </li>
    <li class="navigation-item-short" *ngIf="isGuestUser && !isUspsUser && !isDDUser">
      <a href="#" routerLink="/claims/help" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <span class="new-question">
          <span class="txt">{{ 'claims.claimMenus.claimsHelp' | translate }}</span></span>
      </a>
    </li>

    <li class="navigation-item-question-mark"
      *ngIf="!isGuestClaim && !isAdjuster && !isUspsUser && hasSmartUpload && (userPermission.isSmartUpload) && !isDDUser">
      <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" (click)="startSmartUpload($event)">
        <span class="new-question">
          <span class="txt">{{'smartUpload.title' | translate}}</span>
          &nbsp;<img src="../../../../assets/images/question-circle.svg"></span>
      </a>
    </li>
    <!-- New DD Visibility Item: Only viewable to admins of IS Connect Policies -->
    <!-- <li class="navigation-item-question-mark"
      *ngIf="!isGuestClaim && !isAdjuster && !isUspsUser && !isDDUser && isUsaUser && hasDDVisibility">
      <a (click)="openShipVision($event)" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
        {{'app.navigation.deliveryDefenseShipVision' | translate }}
      </a>
    </li> -->

    <li class="navigation-item-question-mark" *ngIf="!isGuestClaim && !isAdjuster && !isUspsUser && isUsaUser && !isDDUser && isDDApplicable">
      <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" (click)="validateDeliveryDefense($event)">
        <span class="new-question">
          <span class="txt">{{'app.navigation.deliveryDefense' | translate}}</span>
          &nbsp;<img src="../../../../assets/images/question-circle.svg"></span>
      </a>
    </li>

    <li class="navigation-item-question-mark" *ngIf="isAdjuster && !isUspsUser">
      <a href="#" routerLink="/adjusterDashboard" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
        <span class="new-question">
          <span class="txt">{{'app.navigation.adjuster.dashboard' | translate}}</span>
          &nbsp;<img src="../../../../assets/images/question-circle.svg"></span>
      </a>
    </li>
    <!-- New DD Visibility Item: Only viewable to admins of IS Connect Policies -->
    <li class="navigation-item-question-mark"
      *ngIf="!isGuestClaim && !isAdjuster && !isUspsUser && !isDDUser && isUsaUser && hasDDVisibility">
      <a (click)="showDdVisibilityMenu()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
        <span class="new-question">
          <span class="txt">{{'app.navigation.deliveryDefenseShipVision' | translate}}</span>
          &nbsp;<img src="../../../../assets/images/question-circle.svg"></span>
      </a>
    </li>
  </ul>

  <div *ngIf="isDDVisibilityMenu" class="visibility-sidebar" id="popupDiv">
    <div class="title">VISIBILITY</div>
    <div class="green-bar"></div>
    <div class="link-container">
      <div class="text"><a href="{{ visibilityURL }}" (click)="handleClick()" target="_blank" class="link" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: false}">
          Visibility Dashboard
        </a></div><mat-icon>chevron_right</mat-icon>
    </div>
    <div class="link-container">
      <div class="text">
        <a href="#" (click)="handleClick()" routerLink="/dd-visibility" class="link" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: false}">
          Upgrade or Change Plan
        </a>
      </div><mat-icon>chevron_right</mat-icon>
    </div>
  </div>

  <div class="nav-welcome-desktop" *ngIf="fName.length>0">
    <mat-list-item>
      <button class="welcome-button round-button" #clickMenuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="!isDDUser ? clickMenu : ''" #tooltip="matTooltip" [matTooltip]="loginName"
        [matTooltipPosition]="position.value!" [matTooltipHideDelay]="5000">
        {{ fName | titlecase }}
      </button>
      <mat-menu overlapTrigger="false" #clickMenu="matMenu">

        <div *ngIf="this.policyInfoList.length > 0 && !isGuestUser && (!isUspsUser)">
          <div *ngFor="let item of policyInfoList" class="policies">
            <div *ngIf="item.isActive" class="policy-active">
              <div class="policy-login-info">
                <button class="welcome-button round-button">
                  {{ fName | titlecase }}
                </button>
                <div *ngIf="!isThreeContractNo" class="title"> Policy #{{item.policyNumber}} - {{item.productType}}</div>
                <div *ngIf="isThreeContractNo" class="title"> Contract #{{item.policyNumber}}</div>
                <div class="check-circle"><img src="../../../../../assets/images/check-circle.svg"></div>
              </div>
              <div class="nickName" *ngIf="(!isNickNameEdit)">{{item.nickName}}<mat-icon
                  (click)="editNickName($event, item.nickName)">edit</mat-icon></div>
              <div class="nickName" *ngIf="isNickNameEdit">
                <form [formGroup]="nickNameFormGroup" class="nickNameForm">
                  <mat-form-field (click)="editNickName($event, item.nickName)">
                    <div class="custom-input">
                      <input matInput [(ngModel)]="item.nickName" (click)="editNickName($event, item.nickName)"
                        formControlName="nickNameFormControl" (keydown.Tab)="updateNickName($event, item)"
                        (keyup.enter)="updateNickName($event, item)">
                    </div>
                    <mat-icon matSuffix (click)="closeNickName($event)">cancel</mat-icon>
                  </mat-form-field>
                </form>
              </div>
              <div class="insured">{{item.policyHoldersName}} - <span class="current">Current Viewing</span></div>
            </div>

            <div class="policy-in-active" *ngIf="!item.isActive" (click)="togglePolicy(item)">
              <div class="policy-login-info">
                <button class="welcome-button round-button">
                  {{ fName | titlecase }}
                </button>
                <div class="title"> Policy #{{item.policyNumber}} - {{item.productType}}</div>
              </div>
              <div class="nickName">{{item.nickName}}</div>
              <div class="insured">{{item.policyHoldersName}}</div>
            </div>
          </div>
        </div>

        <div *ngIf="this.policyInfoList.length === 0 && !isGuestUser" class="loading">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading Policies...</span>
          </div>
        </div>

        <div class="btnContainer" *ngIf="((!isAdjuster) && (!isUspsUser) && (isUsaUser) && (canManageUsers)) && !isGuestUser && !isThreeContractNo">
          <button class="upsc-white-button" (click)="addPolicy()" [disabled]="disableAddPolicy"><mat-icon
              class="icon-key">add</mat-icon>{{'policies.policy.addAnotherPolicy' | translate}}</button>
        </div>

        <button [hidden]="!showRLSMenuItems" *ngIf="!isAdjuster && !isUspsUser && !isGuestUser" mat-menu-item routerLink='/dashboard'
          (click)="tagging('dashboard')">{{ 'app.header.accountitems.dashboard' | translate }}</button>
        <button [hidden]="!showRLSMenuItems || isThreeContractNo" *ngIf="(!isAdjuster) && (!isUspsUser) && !isGuestUser" mat-menu-item routerLink='/profile'
          (click)="tagging('profile')">{{ 'app.header.accountitems.profile' | translate }}</button>
        <button *ngIf="isThreeContractNo" mat-menu-item routerLink='/profile/security'
          (click)="tagging('profile')">{{ 'app.header.accountitems.profile' | translate }}</button>
        <button [hidden]="!showRLSMenuItems" *ngIf="((!isAdjuster) && (!isUspsUser) && (isUsaUser) && (canManageUsers)) && !isGuestUser"
          mat-menu-item routerLink='/manage-users'>{{ 'app.header.accountitems.manage' | translate }}</button>
          <a [hidden]="!showRLSMenuItems || isThreeContractNo" mat-menu-item *ngIf="(isUsaUser)" href="https://bit.ly/isonlguide" target="_blank"
            rel="noopener noreferrer">
            {{ 'app.header.accountitems.userguide' | translate }}<mat-icon class="icon-key">launch</mat-icon>
          </a>
        <button [hidden]="!showRLSMenuItems" *ngIf="canChangePolicy" mat-menu-item (click)="resetPolicy()">Change
          Policy</button>
        <button [hidden]="(!isCCPSelfServicePortal)" mat-menu-item routerLink='/self-service'>Guest Link Settings</button>
        <button mat-menu-item (click)="contactUs()" *ngIf="!isGuestUser">
          {{ 'app.navigation.contactUs' | translate }}
        </button>
        <button mat-menu-item (click)="logout($event)" [class.border-top]="showRLSMenuItems">{{
          'app.header.accountitems.logout' | translate }}</button>
      </mat-menu>
    </mat-list-item>
  </div>

  <div class="nav-welcome-mobile">
    <mat-list-item>
      <button class="welcome-button round-button" #clickMenuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="!isDDUser ? clickMenu : ''" #tooltip="matTooltip" [matTooltip]="loginName"
        [matTooltipPosition]="position.value!" [matTooltipHideDelay]="5000">
        {{ fName | titlecase }}
      </button>
      <mat-menu overlapTrigger="false" #clickMenu="matMenu" class="position">
        <div *ngIf="this.policyInfoList.length > 0 && !isGuestUser && (!isUspsUser)">
          <div *ngFor="let item of policyInfoList" class="policies">
            <div *ngIf="item.isActive" class="policy-active">
              <div class="policy-login-info">
                <button class="welcome-button round-button">
                  {{ fName | titlecase }}
                </button>
                <div class="title"> Policy #{{item.policyNumber}} - {{item.productType}}</div>
                <div class="check-circle"><img src="../../../../../assets/images/check-circle.svg"></div>
              </div>
              <div class="nickName" *ngIf="!isNickNameEdit">{{item.nickName}}<mat-icon
                  (click)="editNickName($event, item.nickName)">edit</mat-icon></div>
              <div class="nickName" *ngIf="isNickNameEdit">
                <form [formGroup]="nickNameFormGroup" class="nickNameForm">
                  <mat-form-field (click)="editNickName($event, item.nickName)">
                    <div class="custom-input">
                      <input matInput [(ngModel)]="item.nickName" (click)="editNickName($event, item.nickName)"
                        formControlName="nickNameFormControl" (keydown.Tab)="updateNickName($event, item)"
                        (keyup.enter)="updateNickName($event, item)">
                    </div>
                    <mat-icon matSuffix (click)="closeNickName($event)">cancel</mat-icon>
                  </mat-form-field>
                </form>
              </div>
              <div class="insured">{{item.policyHoldersName}} - <span class="current">Current Viewing</span></div>
            </div>
            <div class="policy-in-active" *ngIf="!item.isActive" (click)="togglePolicy(item)">
              <div class="policy-login-info">
                <button class="welcome-button round-button">
                  {{ fName | titlecase }}
                </button>
                <div class="title"> Policy #{{item.policyNumber}} - {{item.productType}}</div>
              </div>
              <div class="nickName">{{item.nickName}}</div>
              <div class="insured">{{item.policyHoldersName}}</div>
            </div>
          </div>
        </div>

        <div *ngIf="this.policyInfoList.length === 0 && !isGuestUser" class="loading">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading Policies...</span>
          </div>
        </div>

        <div class="btnContainer" *ngIf="((!isAdjuster) && (!isUspsUser) && (isUsaUser) && (canManageUsers)) && !isGuestUser && !isThreeContractNo">
          <button class="upsc-white-button" (click)="addPolicy()" [disabled]="disableAddPolicy">
            <mat-icon class="icon-key">add</mat-icon>
            {{'policies.policy.addAnotherPolicy' | translate}}
          </button>
        </div>
        <button [hidden]="!showRLSMenuItems" *ngIf="!isAdjuster && !isUspsUser && !isGuestUser" mat-menu-item routerLink='/dashboard'
          (click)="tagging('dashboard')">{{ 'app.header.accountitems.dashboard' | translate }}</button>
        <button [hidden]="!showRLSMenuItems || isThreeContractNo" *ngIf="(!isAdjuster) && (!isUspsUser) && !isGuestUser" mat-menu-item routerLink='/profile'
          (click)="tagging('profile')">{{ 'app.header.accountitems.profile' | translate }}</button>
        <button *ngIf="isThreeContractNo" mat-menu-item routerLink='/profile/security'
          (click)="tagging('profile')">{{ 'app.header.accountitems.profile' | translate }}</button>
        <button [hidden]="!showRLSMenuItems" *ngIf="((!isAdjuster) && (!isUspsUser) && (isUsaUser) && (canManageUsers)) && !isGuestUser"
          mat-menu-item routerLink='/manage-users'>{{ 'app.header.accountitems.manage' | translate }}</button>
          <a [hidden]="!showRLSMenuItems || isThreeContractNo" mat-menu-item *ngIf="(isUsaUser)" href="https://bit.ly/isonlguide" target="_blank"
            rel="noopener noreferrer">
            {{ 'app.header.accountitems.userguide' | translate }}<mat-icon class="icon-key">launch</mat-icon>
          </a>
        <button [hidden]="!showRLSMenuItems" *ngIf="canChangePolicy" mat-menu-item (click)="resetPolicy()">Change
          Policy</button>
        <button (click)="contactUs()" mat-menu-item *ngIf="!isGuestUser">
          {{ 'app.navigation.contactUs' | translate }}
        </button>
        <button mat-menu-item (click)="logout($event)" [class.border-top]="showRLSMenuItems">
          {{ 'app.header.accountitems.logout' | translate }}
        </button>
      </mat-menu>
    </mat-list-item>
    <button class="contact-button" *ngIf="!isAdjuster && !isDDUser">
      <mat-icon (click)="isCollapsed = !isCollapsed">menu</mat-icon>
    </button>
  </div>

  <div *ngIf="!isCollapsed && (!isUspsUser)" class="sidebar">
    <div class="well well-lg">
      <button [hidden]="!showRLSMenuItems" *ngIf="!isAdjuster && !isDDUser && (!isGuestClaim || isHPGuestUser)"
        mat-menu-item routerLink='/dashboard' (click)="tagging('dashboard')">{{ 'app.navigation.dashboard' | translate
        }}</button>

      <button [hidden]="!showRLSMenuItems" [class.border-top]="showRLSMenuItems"
        *ngIf="!isAdjuster && !isDDUser && !isThreeContractNo && (!isGuestClaim || isHPGuestUser)" mat-menu-item routerLink='/policies'
        (click)="tagging('policies')">{{ 'app.navigation.policies' | translate }}</button>

      <button [hidden]="!showRLSMenuItems" [class.border-top]="showRLSMenuItems"
        *ngIf="(userPermission.isClaimView) || (userPermission.isFileClaim) && !isAdjuster && !isDDUser && (!isGuestClaim || isHPGuestUser)"
        mat-menu-item routerLink='/claims' (click)="tagging('claims')">{{ 'app.navigation.claims' | translate }}
      </button>

      <button [hidden]="!showRLSMenuItems" [class.border-top]="showRLSMenuItems"
        *ngIf="(userPermission.isBillingView) && !isAdjuster && !isGuestClaim && showBilling && !isThreeContractNo" mat-menu-item
        routerLink='/billing' (click)="tagging('billing')">
        <span *ngIf="!(isUsaUser && billingWallet)">{{ 'app.navigation.billing' | translate }}</span>
        <span *ngIf="(isUsaUser && billingWallet)">{{ 'app.navigation.billingWallet' | translate }}</span>
      </button>

      <button [hidden]="!showRLSMenuItems" [class.border-top]="showRLSMenuItems" *ngIf="!isGuestUser && !isDDUser"
        mat-menu-item routerLink='/claims/new' (click)="tagging('claims/new')">{{ 'claims.claimMenus.fileNewClaim' |
        translate
        }}</button>

      <button [hidden]="!showRLSMenuItems" *ngIf="isGuestUser && !isDDUser" mat-menu-item routerLink='/claims/new'
        (click)="tagging('claims/new')">{{ 'claims.claimMenus.fileNewClaim' |
        translate
        }}</button>


      <button [hidden]="!showRLSMenuItems" [class.border-top]="showRLSMenuItems" *ngIf="isGuestUser && !isDDUser"
        mat-menu-item routerLink='/claims/new' (click)="tagging('claims/help')">{{ 'claims.claimMenus.claimsHelp' |
        translate
        }}</button>

      <button [hidden]="!showRLSMenuItems" [class.border-top]="showRLSMenuItems"
        *ngIf="!isGuestClaim && !isAdjuster && hasSmartUpload && (userPermission.isSmartUpload) && !isDDUser"
        mat-menu-item (click)="startSmartUpload($event)">
        {{ 'smartUpload.title' | translate }}</button>
      <button [hidden]="!showRLSMenuItems" [class.border-top]="showRLSMenuItems"
        *ngIf="!isGuestClaim && !isAdjuster && !isDDUser && isUsaUser && isDDApplicable" mat-menu-item
        (click)="validateDeliveryDefense($event)">DeliveryDefense</button>
      <button [hidden]="!showRLSMenuItems" [class.border-top]="showRLSMenuItems"
        *ngIf="!isGuestClaim && !isAdjuster && !isDDUser && isUsaUser && hasDDVisibility" mat-menu-item
        (click)="showDdVisibilityMenu()">
        {{'app.navigation.deliveryDefenseShipVision' | translate }}
      </button>

      <button [hidden]="!showRLSMenuItems" [class.border-top]="showRLSMenuItems" *ngIf="isAdjuster" mat-menu-item
        routerLink='/adjusterDashboard' (click)="tagging('adjusterDashboard')">{{ 'app.navigation.adjuster.dashboard'
        |
        translate }}</button>
    </div>
  </div>
