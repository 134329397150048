<div class="upsc-custom-login">
  <div class="content">
    <div class="title">
      <div class="login-title">{{ 'login.title' | translate }}</div>
    </div>
    <div class="bar">
      <div class="login-bar"></div>
    </div>
    <div class="center">
      <form [formGroup]="UserDetailsForm">
        <div class="input-er-wrapper"> 
          <mat-form-field>
            <mat-label>{{ 'login.form.userId' | translate }}</mat-label>
            <input matInput pattern="\S+" required formControlName="userid">
            <mat-error>
              <div class="error-input">
                <mat-icon>report_problem</mat-icon>
                <div class="error-text">{{ 'login.form.userIdRequired' | translate }}</div>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="input-er-wrapper"> 
          <mat-form-field>
            <mat-label>{{ 'login.form.password' | translate }}</mat-label>
            <input matInput required type="password" formControlName="password">
            <mat-error>
              <div class="error-input">
                <mat-icon>report_problem</mat-icon>
                <div class="error-text">{{ 'login.form.passwordRequired' | translate }}</div>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="error" *ngIf="loginFailed">
          {{ 'login.form.errorReturned' | translate }}
        </div>
        <div class="error" *ngIf="showContactError">
          {{ 'login.form.showContactError' | translate }}
        </div>
        <div class="mb-3" *ngIf="mustCompleteRecaptcha"><re-captcha formControlName="recaptchaControl"></re-captcha></div>
        <div class="error" *ngIf="recaptchaError">{{ 'login.form.recaptcha' | translate }}</div>
        <div class="login-button">
          <button class="upsc-button" type="submit" *ngIf="!loading" (click)="reCAPTCHA()">
            <div class="btn-text-icon">
              <div class="text">{{ 'login.form.login' | translate }}</div><mat-icon>chevron_right</mat-icon>
            </div>
          </button>
          <button class="upsc-button loading" type="submit" *ngIf="loading">
            <div class="btn-text-icon">
              <div class="text">{{ 'login.form.login' | translate }}</div>
            </div>
            <div class="spinner-border" role="status">
              <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
            </div>
          </button>
          <div class="help">
            <a [upscRouterLink]="['/login-help']" (click)="tagging('help')">{{ 'login.form.needHelp' |
              translate }}</a><mat-icon>chevron_right</mat-icon>
          </div>         
        </div>
      </form>
    </div>
    <div class="register">
      {{ 'login.notYet' | translate }}
      <a class="link" [upscRouterLink]="['/create-account']" (click)="tagging('signup')">{{ 'login.createAccount' |
        translate }}</a>
    </div>
    <div *ngIf="scriptError" class="response-error">Invalid Input</div>
    <div class="disclaimer">
      This site uses reCAPTCHA v3. Your use of this website is subject to Google’s privacy policy and terms of use.
    </div>
  </div>
</div>