import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { iif, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { TupssService } from './tupss.service';

@Injectable({
  providedIn: 'root'
})
export class PolicyResolver  {
  constructor ( private router: Router,
    private route: ActivatedRoute,
    private tupssService: TupssService,
    private userService: UserService) {  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let siteId = this.tupssService.userData?.siteId;
    let contractId = this.tupssService.userData?.contractId;
    let currentRoute = state.url;
    //if we can't find contractId and siteId upon refresh/navigation back from dpat, try to retrieve it from sessionStorage
    if(!contractId && !siteId) {
      let claim = JSON.parse(sessionStorage.getItem('claim'));
      if (claim) {
        siteId = claim.storeNumber;
        contractId = claim.policy.policyNumber;
      }
    }
    if(!contractId && !siteId) {
      let currentPage = this.tupssService.getItem('siteFor');
      if(currentPage) {
        this.router.navigate(['tfcp', 'store'], {fragment: currentPage});
      }
      else if (this.tupssService.getItem('type') != 'tccp') {
        this.router.navigate(['tfcp']);
      }
    }
    if (sessionStorage.getItem('access_token') && sessionStorage.getItem('policyDetails') && sessionStorage.getItem('userDetails') && !window.location.href.includes('tfcp/store') && (contractId != undefined && JSON.parse(sessionStorage.getItem('policyDetails')).policyNumber == contractId)) {
      //Do nothing, user can move forward if they have these already.
      //The check for policy number equaling contractID is so that in the event that the UI 
      //had to change the contractID to a different one, then we will subsequently get the new policy info based on that.
      //ContractID can change if user is TFCP and has multiple policies coming from 1 siteID.
    } else if (sessionStorage.getItem('access_token') && sessionStorage.getItem('policyDetails') && sessionStorage.getItem('userDetails') && !window.location.href.includes('tfcp/store') && contractId == undefined) {
      //user refreshes page on claim detail link
    } else if (sessionStorage.getItem('access_token') && !sessionStorage.getItem('policyDetails') && sessionStorage.getItem('userDetails') && JSON.parse(sessionStorage.getItem('userDetails')).policyNumber && currentRoute != '/tupss/file' && currentRoute != '/tfcp/claims' && currentRoute != '/tfcp/policy') {
      //Used for claim detail when coming from link
      return this.userService.getPolicySearch(JSON.parse(sessionStorage.getItem('userDetails')).policyNumber);
    } else {
      return iif(() => !!contractId,  of(contractId), this.tupssService.getContractId(siteId)).pipe(
        switchMap(
          (contractData) => {
            let contractId: string;
            if(typeof contractData == 'object') {
              contractId = contractData.contractIDResponse[0].contractID;
            }
            else {
              contractId = contractData;
            }
            return this.userService.getPolicySearch(contractId);
          }
        ),
        map (data => {
          //This is where the normal TCCP flow goes. If user enters the right verification code, we call search policy from HERE
          if (data?.message?.toLowerCase() == 'succeeded' && data?.data) {
            sessionStorage.setItem('policyDetails', JSON.stringify(data.data));
            return true;
          } else {
            if (this.tupssService.getItem('type') == 'tccp') {
              this.router.navigate(['tccp']);
              this.tupssService.policySearchError = true;
              return false;
            } else {
              this.router.navigate(['tfcp']);
              this.tupssService.policySearchError = true;
              return false;
            }
          }
        }));
    }
  }
}
