import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[upscTrim]'
})
export class TrimDirective {

  constructor(
    private ngControl: NgControl
  ) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event);
  }

  onInputChange(input) {
    let trimmedInput = input.trim();
    if (trimmedInput.length === 0) {
      trimmedInput = '';
    }
    if (input == trimmedInput) {

    } else {
      this.ngControl.control.setValue(trimmedInput);
      this.ngControl.valueAccessor.writeValue(trimmedInput);
    }
  }

}
