import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { LoginService } from 'src/app/services/login.service';
import { PolicyService } from 'src/app/services/policy.service';
import { ScriptService } from 'src/app/services/script.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  // templateUrl: './auth.component.html',
  selector: 'upsc-auth',
  templateUrl: './unified.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  tokenError: boolean;
  errorOccurred: boolean;
  idData: boolean = false;
  workflow: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private loginService: LoginService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  ) {}

  ngOnInit() {
    let returnURL = "login";
    let queryParams = this.route.snapshot.queryParams;
    let urlFragment = this.route.snapshot.fragment;
    if(queryParams['returnUrl']) {
      sessionStorage.setItem('returnUrl', queryParams['returnUrl']),
      this.router.navigate([], {
        queryParams: {
          'returnUrl': null,
        },
        queryParamsHandling: 'merge'
      })
    }
    if (queryParams.UID && queryParams.page) {
      this.userService.getAutoAuthProfile(queryParams.UID).subscribe(
        profileData => {
          if (profileData) {
            this.idData = true;
            sessionStorage.setItem('access_token', profileData.accessToken);
            sessionStorage.setItem('userid', profileData.userId);
            delete profileData.accessToken;
            sessionStorage.setItem('userDetails', JSON.stringify(profileData));
            this.userService.setUserPermissions(profileData.permissions);
            if(profileData.userRoleID == '7') {
              this.workflow='DD';
              sessionStorage.setItem('DDFlow', queryParams.page);
            }
            this.userService.getUserInfo();
            // if (profileData.userRoleID !== '7') {
            sessionStorage.setItem('refresh_token', profileData.refreshToken);
            this.userService.getPolicySearch(profileData.policyNumber).subscribe(
              policyData => {
                if (policyData?.data && policyData?.message?.toLowerCase() == 'succeeded') {
                  sessionStorage.setItem('policyDetails', JSON.stringify(policyData.data));
                  returnURL = queryParams.page;
                  this.router.navigateByUrl(returnURL);
                } else {
                  sessionStorage.clear();
                  this.errorOccurred = true;
                }
              },
              error => {
                sessionStorage.clear();
                this.errorOccurred = true;
              }
            );
            // }
            // else {
            // if(profileData.policyNumber) {
            // sessionStorage.setItem('userDetails', JSON.stringify({...profileData, userId: profileData.policyNumber.split('-')[1]}));
            // sessionStorage.setItem('policyDetails', JSON.stringify({policyNumber: profileData.policyNumber, policyType: {value: ''}}));
            // returnURL = `/${queryParams.page}`;
            // sessionStorage.setItem('DDFlow', queryParams.page);
            // this.router.navigateByUrl(returnURL);
            // }
            // else {
            // this.errorOccurred = true;
            // }
            // }
          }
          else {
            this.tokenError = true;
            this.errorOccurred = true;
          }
        },
        err => {
          this.errorOccurred = true;
        }
      );
    }
    else if(urlFragment) {
      if(urlFragment.includes('id_token=')) {
        let token = urlFragment.split('=')[1];
        this.loginService.userDetails(token).subscribe(
          data => {
            sessionStorage.setItem('access_token', data.access_token);
            sessionStorage.setItem('refresh_token', data.refresh_token);
            this.userService.getUserInfo();
            this.router.navigateByUrl('/dashboard');
          },
          error => {
            this.errorOccurred = true;
            this.router.navigateByUrl(returnURL)
          }
        );
      }
      else if(urlFragment.includes('&code=')) {
        /* if(this.msalService.instance.getAllAccounts().length > 0) {
          this.decodeToken(this.msalService.instance.getAllAccounts()[0].idToken);
        }
        else { */
          this.msalBroadcastService.msalSubject$.pipe(
            filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
            takeUntil(this._destroying$)
          )
          .subscribe((result: EventMessage) => {
            console.log('Log in succeeded - ', result);
            sessionStorage.setItem('idToken', result.payload['idToken']);
            sessionStorage.setItem('refresh_token', result.payload['refreshToken']);
            this.decodeToken(result.payload['accessToken']);
          });
        // }
      }
      else if(urlFragment.includes('error=')) {
        this.errorOccurred = true;
      }
    }
    else {
      if(sessionStorage.getItem('access_token')) {
        this.decodeToken(sessionStorage.getItem('access_token'));
      }
      else {

          if (this.msalGuardConfig.authRequest){
            this.msalService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
          } else {
            this.msalService.loginRedirect();
          }
      }
    }
  }

  decodeToken (token) {
    sessionStorage.setItem('access_token', token)
    this.router.navigateByUrl('authorize');
  }

  private readonly _destroying$ = new Subject<void>();
  ngOnDestroy(): void {
    this.msalService.instance.clearCache();
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  emailAddress = '';
  showCodeVerification = false;
  checkVerification(data) {
  }
}

