<div class="ccp-landing-wrapper" *ngIf="!partnerCustomizationError">
  <div *ngIf="currentStep == 0">
    <ng-container *ngTemplateOutlet="welcomeTemplate"></ng-container>
  </div>
  <div *ngIf="currentStep == 1">
    <ng-container *ngTemplateOutlet="verificationTemplate"></ng-container>
  </div>
</div>

<div class="ccp-landing-wrapper" *ngIf="partnerCustomizationError">
  <div class="partner-customization-error">
    Something Went Wrong! Please try again later.
  </div> 
</div>

<ng-template #welcomeTemplate>
  <form [formGroup]="emailForm">
    <div class="start-claim-block">
      <div class="form-box">
        <div class="title">Start a Claim</div>
        <div class="divider-bar"></div>
        <div class="login-form d-flex pt-3">
          <div class="email-label">Email Address*</div>
          <div class="form-inputs">
            <mat-form-field class="width-300">
              <mat-label>Email Address</mat-label>
              <input matInput formControlName="emailAddress">
              <mat-error *ngIf="emailForm.controls['emailAddress'].errors">
                <div *ngIf="emailForm.get('emailAddress').errors?.required">
                  Email Address is required.
                </div>
                <div *ngIf="emailForm.get('emailAddress').errors?.pattern">
                  Please enter a valid email.
                </div>                
              </mat-error>
            </mat-form-field>
            <div *ngIf="policyNotMatchError" class="error">
              This email address is not set up to file claims for this policy.
            </div>
          </div>
          <br>
          <div class="captcha-control reSize">
            <re-captcha formControlName="recaptchaControl"></re-captcha>
          </div>
          <div class="buttons">
            <button class="upsc-button" [disabled]="emailForm.invalid" (click)="getStarted()" *ngIf="!loading">
              Get Started
              <mat-icon class="mobile-format">keyboard_arrow_right</mat-icon>
            </button>
            <button class="next-step loading d-flex align-items-center justify-content-between" *ngIf="loading">
              <div>Get Started</div>
              <div class="spinner-border" role="status">
                <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #verificationTemplate>
  <upsc-email-address-verification [style]="'happy-returns'" [email]="emailAddress" (verifiedResponse)="verifyResponse($event)">
  </upsc-email-address-verification>
</ng-template>