import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailAddressVerificationComponent } from './email-address-verification.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';

@NgModule({
  declarations: [
    EmailAddressVerificationComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  exports: [
    EmailAddressVerificationComponent
  ],
  providers: [
      TranslatePipe
    ]
})
export class EmailAddressVerificationModule { }
