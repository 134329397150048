import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UtilityService } from '../services/utility.service';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { ProductComponent } from './components/product/product.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ContactUsComponent } from './dialogs/contact-us/contact-us.component';
import { ClaimMaskDirective } from '../shared/directives/claim-mask.directive';
import { AccessDeniedComponent } from './dialogs/access-denied/access-denied.component';
import { CarrierSearchPipe } from '../pages/claims/pipes/carrier-search.pipe';
import { NetworkChangeComponent } from './dialogs/network-change/network-change.component';
import { UnableToFulfillComponent } from './components/unable-to-fulfill/unable-to-fulfill.component';
import { AccordionAndTableComponent } from './components/accordion-and-table/accordion-and-table.component';
import { DragNDropDirective } from './directives/drag-n-drop.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CollapsiblePanel2Module } from './components/collapsible-panel2/collapsible-panel2.module';
import { PayeeBankPopupComponent } from './components/payee-bank-popup/payee-bank-popup.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { NgxCurrencyDirective } from 'ngx-currency';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { AmountSearchFormComponent } from './components/amount-search-form/amount-search-form.component';
import { UPSCRouterLink } from './directives/router-link.directive';
import { NotificationBoxModule } from './components/notification-box/notification-box.module';
import { CustomContactUsComponent } from './dialogs/Tupss-contact-us/tupss-contact-us.component';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { CurrencyMaskingDirective } from '../pages/claims/directive/currency-masking-directive';
import { DebounceDirective } from './directives/debounce.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PhoneNumberDirective } from './directives/phone-number.directive';
import { AddressValidationComponent } from './components/address-validation/address-validation.component';
import { TupssHomeFooterComponent } from './components/tupss-home-footer/tupss-home-footer.component';
import { RemoveCustomizableDataPopupComponent } from './components/remove-customizable-data-popup/remove-customizable-data-popup.component';
import { AddCustomizableDataPopupComponent } from './components/add-customizable-data-popup/add-customizable-data-popup.component';
import { WithdrawPopupComponent } from './components/withdraw-popup/withdraw-popup.component';
import { RemovePaymentMethodPopupComponent } from './components/remove-payment-method-popup/remove-payment-method-popup.component';
import { UsCheckboxComponent } from './components/us-checkbox/us-checkbox.component';
import { AccessPendingComponent } from './dialogs/access-pending/access-pending.component';
import { NewNotificationBoxModule } from './components/new-notification-box/new-notification-box.module';
import { DeliveryDefenseAgreementComponent } from './components/delivery-defense-agreement/delivery-defense-agreement.component';
import { DeliveryDefenseErrorComponent } from './components/delivery-defense-error/delivery-defense-error.component';
import { AdvancedSearchPopupComponent } from './components/advanced-search-popup/advanced-search-popup.component';
import { MessageListComponent } from '../pages/claims/components/message-list/message-list.component';
import { MessageAdjusterComponent } from '../pages/claims/components/message-adjuster/message-adjuster.component';
import { CcpTermsComponent } from './dialogs/ccp-terms/ccp-terms.component';
import { ColumnSettingsComponent } from './components/column-settings/column-settings.component';
import { CardDisplayComponent } from './components/card-display/card-display.component';
import { UserFilterComponent } from './components/user-filter/user-filter.component';
import { PaginateListComponent } from './components/paginate-list/paginate-list.component';
import { ConfirmContactCardComponent } from './components/confirm-contact-card/confirm-contact-card.component';
import { EmailVerificationComponent } from '../pages/claims-custom/tupss/tupss-edit/components/email-verification/email-verification.component';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import * as ENV from '../../environments/environment';
import { RectangleProgressBarComponent } from './components/rectangle-progress-bar/rectangle-progress-bar.component';
import { SubPoliciesComponent } from './components/sub-policies/sub-policies.component';
import { AccountsShipperComponent } from './components/accounts-shipper/accounts-shipper.component';
import { CheckFeePopupComponent } from './components/check-fee-popup/check-fee-popup.component';
import { FilterUpsPipe } from './pipes/filter-ups.pipe';
import { PoliciesSubTabComponent } from './components/policies-sub-tab/policies-sub-tab.component';
import { DigitalWalletTcsPopupComponent } from './components/digital-wallet-tcs-popup/digital-wallet-tcs-popup.component';
import { DwRecipientComponent } from './components/dw-recipient/dw-recipient.component';
import { ClaimHelpComponent } from '../pages/claims/components/help/claim-help.component';
import { TrimDirective } from './directives/trim.directive';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule.forChild(),
        MatBadgeModule,
        MatDialogModule,
        MatIconModule,
        MatSelectModule,
        MatInputModule,
        MatRadioModule,
        MatAutocompleteModule,
        MatExpansionModule,
        MatProgressBarModule,
        NgbModule,
        CollapsiblePanel2Module,
        MatListModule,
        MatCheckboxModule,
        NgxCurrencyDirective,
        NotificationBoxModule,
        NewNotificationBoxModule,
        MatDatepickerModule,
        NgxMaskDirective,
        NgxMaskPipe,
        RecaptchaModule,
        RecaptchaFormsModule,
    ],
    declarations: [
        PrivacyComponent,
        ProductComponent,
        ContactUsComponent,
        CustomContactUsComponent,
        ClaimMaskDirective,
        AccessDeniedComponent,
        CarrierSearchPipe,
        NetworkChangeComponent,
        UnableToFulfillComponent,
        AccordionAndTableComponent,
        DragNDropDirective,
        PayeeBankPopupComponent,
        AmountSearchFormComponent,
        UPSCRouterLink,
        PhoneNumberPipe,
        CurrencyMaskingDirective,
        DebounceDirective,
        PhoneNumberDirective,
        TrimDirective,
        AddressValidationComponent,
        TupssHomeFooterComponent,
        RemoveCustomizableDataPopupComponent,
        AddCustomizableDataPopupComponent,
        WithdrawPopupComponent,
        RemovePaymentMethodPopupComponent,
        UsCheckboxComponent,
        AccessPendingComponent,
        DeliveryDefenseAgreementComponent,
        DeliveryDefenseErrorComponent,
        AdvancedSearchPopupComponent,
        MessageListComponent,
        MessageAdjusterComponent,
        CcpTermsComponent,
        ColumnSettingsComponent,
        CardDisplayComponent,
        UserFilterComponent,
        PaginateListComponent,
        ConfirmContactCardComponent,
        EmailVerificationComponent,
        RectangleProgressBarComponent,
        SubPoliciesComponent,
        AccountsShipperComponent,
        CheckFeePopupComponent,
        FilterUpsPipe,
        PoliciesSubTabComponent,
        DigitalWalletTcsPopupComponent,
        DwRecipientComponent,
        TrimDirective
    ],
    providers: [
        UtilityService,
        DecimalPipe,
        CarrierSearchPipe,
        PhoneNumberPipe,
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: { siteKey: ENV.environment.recaptcha.siteKey } as RecaptchaSettings
        },
        provideNgxMask()
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        MatBadgeModule,
        MatIconModule,
        MatDialogModule,
        MatSelectModule,
        MatInputModule,
        MatRadioModule,
        MatAutocompleteModule,
        CarrierSearchPipe,
        AccordionAndTableComponent,
        MatExpansionModule,
        DragNDropDirective,
        MatProgressBarModule,
        NgbModule,
        CollapsiblePanel2Module,
        AmountSearchFormComponent,
        NgxCurrencyDirective,
        NgxMaskDirective,
        NgxMaskPipe,
        UPSCRouterLink,
        PhoneNumberPipe,
        CurrencyMaskingDirective,
        DebounceDirective,
        MatDatepickerModule,
        PhoneNumberDirective,
        TrimDirective,
        AddressValidationComponent,
        UsCheckboxComponent,
        MessageAdjusterComponent,
        MessageListComponent,
        CardDisplayComponent,
        UserFilterComponent,
        PaginateListComponent,
        ConfirmContactCardComponent,
        EmailVerificationComponent,
        RecaptchaFormsModule,
        RecaptchaModule,
        RectangleProgressBarComponent,
        SubPoliciesComponent,
        AccountsShipperComponent,
        FilterUpsPipe,
        PoliciesSubTabComponent,
        DwRecipientComponent
    ]
})
export class SharedModule {}




