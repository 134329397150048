<form [formGroup]="confirmFormGroup">
  <div class="shared verification-wrapper">
    <div class="d-flex justify-content-center align-items-center flex-column">
      <div class="header">{{ 'createAccount.confirm.title' | translate }}</div>
      <div class="description">{{ 'createAccount.confirm.description' | translate }} {{ 'createAccount.confirm.description2' | translate }}
      </div>
    </div>
    <div class="container-wrapper">
      <div class="input-container">
        <mat-form-field class="plain-form-field">
          <input matInput inputmode="numeric" autofocus class="center-text" #num1 (keyup)="focusThis(2, $event)" (input)="pasted()" formControlName="firstDigit" pattern="([0-9])">
        </mat-form-field>
      </div>
      <div class="input-container">
        <mat-form-field class="plain-form-field">
          <input matInput inputmode="numeric" class="center-text" #num2 (keyup)="focusThis(3, $event)" formControlName="secondDigit" maxlength="1" pattern="([0-9])">
        </mat-form-field>
      </div>
      <div class="input-container">
        <mat-form-field class="plain-form-field">
          <input matInput inputmode="numeric" class="center-text" #num3 (keyup)="focusThis(4, $event)" formControlName="thirdDigit" maxlength="1" pattern="([0-9])">
        </mat-form-field>
      </div>
      <div class="input-container">
        <mat-form-field class="plain-form-field">
          <input matInput inputmode="numeric" class="center-text" #num4 (keyup)="focusThis(5, $event)" formControlName="fourthDigit" maxlength="1" pattern="([0-9])">
        </mat-form-field>
      </div>
      <div class="input-container">
        <mat-form-field class="plain-form-field">
          <input matInput inputmode="numeric" class="center-text" #num5 (keyup)="focusThis(6, $event)" formControlName="fifthDigit" maxlength="1" pattern="([0-9])">
        </mat-form-field>
      </div>
      <div class="input-container">
        <mat-form-field class="plain-form-field">
          <input matInput inputmode="numeric" class="center-text" #num6 (keyup)="focusThis(7, $event)" formControlName="sixthDigit" maxlength="1" pattern="([0-9])">
        </mat-form-field>
      </div>
    </div>
    <div class="error2" *ngIf="verifyCodeError">
      {{ 'createAccount.confirm.form.errorReturned' | translate }}
    </div>
    <div class="verification-buttons" [ngClass]="style ? style : ''">
      <button type="button" class="upsc-secondary-button icon-left mr-3" (click)="cancel()"><mat-icon>keyboard_arrow_left</mat-icon>{{ 'createAccount.confirm.form.cancel' | translate }}</button>
      <button type="button" class="upsc-primary-button icon-right" (click)="checkVerificationCode()" *ngIf="!loading" [disabled]="confirmFormGroup.invalid">{{ 'claims.list.component.pager.next' | translate }}<mat-icon>keyboard_arrow_right</mat-icon></button>
      <button type="button" class="upsc-primary-button icon-right" disabled *ngIf="loading">{{ 'claims.list.component.pager.next' | translate }}<span class="spinner-border"></span></button>
    </div>
  </div>
</form>