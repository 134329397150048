import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BillingService } from 'src/app/services/billing.service';
import { LoginService } from 'src/app/services/login.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'upsc-digital-wallet-tcs-popup',
  templateUrl: './digital-wallet-tcs-popup.component.html',
  styleUrls: ['./digital-wallet-tcs-popup.component.scss']
})
export class DigitalWalletTcsPopupComponent implements OnInit {
  loading: boolean = false;
  sessionError: boolean = false;
  isChecked: boolean = false;
  isUPSCBilled: boolean = false;  //if cash eligible = true, and rewardeligible = true
  isUPSBilled: boolean = false; //if casheligible = true, and rewardeligible = false

  userDetails;
  policyDetails;

  constructor(
    public dialogRef: MatDialogRef<DigitalWalletTcsPopupComponent>,
    public billingService: BillingService,
    public userService: UserService,
    public loginService: LoginService
    ) { 
      this.userDetails = this.userService.getUserInfo();
      this.policyDetails = this.loginService.getPolicyDetails();
    }

  ngOnInit() {
    this.isUPSBilled = this.userService.isUPSBilled();
    this.isUPSCBilled = this.userService.isUPSCBilled();
  }

  onChange(event?) {
    this.isChecked = event;
  }

  enroll() {
    // I think we should close the pop-up after the session transfer occurs. If it doesn't occur (fails), then display error message.
    let userRole = this.determineUserRole();

    this.sessionError = false;
    this.loading = true;
    let request = {
      action: "accesswallet", //Hardcoded
      navigatingSystem: "onl", //Hardcoded
      navigatingSystemRole: userRole,
      productSystem: "gw", //Hardcoded (not coming from cbp/dd)
      productSystemIdentifier1: this.policyDetails?.policyNumber,
      productSystemIdentifier1Name: "policyno",
      productSystemIdentifier2: this.policyDetails?.accountNumber,
      productSystemIdentifier2Name: "pcAccountNumber",
      productType: "iscomplete", //Hardcoded (unless we want to change later based on databricks policytype value)
      country: "us", //Harcoded (wallet is US only)
      locale: "en", //Hardcoded (wallet is US only)
      userIdentifier1Name: "createdbyuserid",
      userIdentifier1: this.userDetails?.userId?.toString(),
      displayName: this.userDetails?.contactName, //QUESTION: Use user's name or policy holder's name?
      userEmail: this.userDetails?.emailAddress, //QUESTION: Do we want to use user's info here or policy's email?
      objectUID: this.userDetails?.objectUID,
      callBackURL: "https://online.upscapital.com", //Hardcoded (we don't need this field, remove if possible)
      returnURL: "https://online.upscapital.com" //Hardcoded (we don't need this field, remove if possible)
    };
    this.billingService.DWstartSession(request).subscribe(
      data => {
        this.loading = false;
        if (data?.data?.url) {
          window.open(data?.data?.url, '_blank');
          this.dialogRef.close(); // Close the pop-up as they get navigated to the new page.
        } else {
          this.sessionError = true;
        }
      }, error => {
        this.loading = false;
        this.sessionError = true;
      }
    );
  }

  close() {
    this.dialogRef.close();
  }

  //They clicked "Request a one-time check"
  startClaimFlow() {
    sessionStorage.setItem('paymentMode', 'check');
    this.dialogRef.close('continueToDPAT')
  }

  determineUserRole() {
    if (this.userService.hasClaimsManagerRole()) {
      return 'claimsmanager';
    } else if (this.userService.hasClaimsFilerRole()) {
      return 'claimsfiler';
    } else if (this.userService.hasBillingManagerRole()) {
      return 'billingmanager';
    } else if (this.userService.hasAccountManagerRole()) {
      return 'accountmanager';
    } else if (this.userService.hasAdministratorRole()) {
      return 'admin';
    } else {
      return 'tandc';
    }
  }
}
