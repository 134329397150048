import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginRoutingModule } from './login-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { LoginComponent } from './login.component';
//import { IdentityLayoutModule } from '../../shared/components/identity-layout/identity-layout.module';
import { LoginLayoutModule } from 'src/app/shared/components/login-layout/login-layout.module';
import { EmailAddressVerificationModule } from 'src/app/shared/components/email-address-verification/email-address-verification.module';

@NgModule({
  imports: [
    CommonModule,
    LoginRoutingModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    SharedModule,
    //IdentityLayoutModule,
    LoginLayoutModule,
    EmailAddressVerificationModule
  ],
  declarations: [
    LoginComponent,
  ],
  providers: []
})
export class LoginModule { }
