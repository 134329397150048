<!-- <div *ngIf="spinner" class="spinner-border" role="status">
  <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
</div> -->
<div>
  <div class="page">
    <div class="title">
      <h3 class="page-title">Enter a policy number</h3>
    </div>
  </div>
  <div class="upsc-custom-internal-dashboard">
    <div class="main">
      <form [formGroup]="policyForm">
        <div class="mb-3">
          <span class="heading">{{ 'adjuster.policy.heading' | translate }}</span>
        </div>
        <div class="field-row">
          <div class="field-label">
            <span class="labelstyle">{{'adjuster.policy.number' | translate}}</span><br>
            <span class="labelSubText">{{'adjuster.policy.version' | translate}}</span>
          </div>
          <div>
            <mat-form-field class="form-spacing">
              <input matInput upscTrim formControlName='policy' placeholder="{{'common.policy' | translate}}" maxLength='25'>
              <mat-error *ngIf="policyForm.controls['policy'].hasError('required')">
                {{'userProfile.payee.errors.required' | translate}}
              </mat-error>
              <mat-error *ngIf="policyForm.controls['policy'].hasError('pattern')">
                {{'userProfile.payee.errors.required' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="systemError" class="error-message">
          {{ 'common.systemError' | translate }}
        </div>
        <div *ngIf="notFound" class="error-message">
          No Policy Found.
        </div>
        <div class="d-flex justify-content-end mt-sm-3">
          <button class="upsc-primary-button" *ngIf="!submitting" (click)="continue()">Continue</button>
          <button class="upsc-primary-button icon-right" *ngIf="submitting" disabled>Continue <span class="spinner-border"></span></button>
        </div>
      </form>
    </div>
  </div>

  <div class="smb" *ngIf="showSMBButton">
    <button class="upsc-primary-button icon-right" (click)="navigateToSMB()">SMB Portal <mat-icon>chevron_right</mat-icon></button>
  </div>
</div>

