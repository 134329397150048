import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AccountService } from 'src/app/services/account.service';
import { BillingService } from 'src/app/services/billing.service';
import { LoginService } from 'src/app/services/login.service';
import { PolicyService } from 'src/app/services/policy.service';
import { ScriptService } from 'src/app/services/script.service';
import { UserService } from 'src/app/services/user.service';
import { AccessDeniedComponent } from 'src/app/shared/dialogs/access-denied/access-denied.component';
import { AccessPendingComponent } from 'src/app/shared/dialogs/access-pending/access-pending.component';
import { IVerificationInterface, emptyVerificationInterface } from 'src/app/shared/models/verification.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'upsc-authorize',
  templateUrl: './unified.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthorizeComponent implements OnInit
  {
  errorOccurred: boolean;
  tokenData: any;
  showCodeVerification: boolean = false;
  emailAddress: string;
  emailPayload: IVerificationInterface = emptyVerificationInterface;

  constructor(
    private router: Router,
    private userService: UserService,
    private loginService: LoginService,
    private policyService: PolicyService,
    private billingService: BillingService,
    private scriptService: ScriptService,
    private dialog: MatDialog,
    private msalService: MsalService,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    let token = sessionStorage.getItem('access_token')
    this.loginService.userDetails2(token).subscribe(
      data => {
        //deny access if user data is missing
        if(!data.policyNumber || !data.approved || !data.active || !data.permissions || data.permissions.length <= 0) {
          if (this.dialog.openDialogs.length == 0) {
            let dialogRef: MatDialogRef<any>;
            if(!data.PolicyNumber) {
              dialogRef = this.dialog.open(AccessDeniedComponent);
            }
            else {
              dialogRef = this.dialog.open(AccessPendingComponent);
            }
            dialogRef.afterClosed().subscribe(
              d => {
                this.onErrors();
              }
            );
          }
        }
        else {
          this.tokenData = data;
          this.emailAddress = data.emailAddress;
          // this.sendVerificationCode();
          this.loadDashboard();
        }
      },
      error => {
        this.errorOccurred = true;
      }
    )
  }

  sendVerificationCode() {
    /* this.emailPayload.data.emailAddress = this.emailAddress;
      this.emailPayload.emailFor = 'GenericVerificationCode';

      this.accountService.createVerificationCode(this.emailPayload).subscribe(
        res => {
          if ((res.responseMessage).toLowerCase() === 'new verification code created' ||
            (res.responseMessage).toLowerCase() === 'code not yet expired') {
              this.showCodeVerification = true;
          }
        }, error => {
          this.errorOccurred = true;
        }
      ); */
  }

  checkVerification(event) {
    if(event?.message == 'cancel') {
      this.onErrors();
    }

    if(event?.isVerified !== true) {
      return;
    }

    this.showCodeVerification = false;
    this.loadDashboard();
  }

  loadDashboard() {
    if (this.tokenData.policyNumber.search(/^\d+-p\d+$/) == 0) {
      this.tokenData.policyNumber = this.tokenData.policyNumber.toUpperCase();
    }
    this.userService.setUserInfo({...this.tokenData, ...{firstName: this.tokenData.contactName?.split(' ')[0] }});
    sessionStorage.setItem('userid', this.tokenData.userId);

    this.userService.setUserPermissions(this.tokenData.permissions);

    this.userService.getPolicySearch(this.tokenData.policyNumber).subscribe(
      policydata => {
        if(!policydata?.data) {
          this.errorOccurred = true;
        }
        else {
          sessionStorage.setItem('policyDetails', JSON.stringify(policydata.data));
          if (this.userService.isUserAdjuster()) {
            this.router.navigateByUrl('/adjusterDashboard');
          }
          else if (policydata.data.policyType.key == 'Flex' && policydata.data.policyType.value == 'Flexible Parcel Insurance' && policydata.data.policySource == 'ISHIPFLEXMC') { // Tupss flex USPS policies check
            this.router.navigateByUrl('/billing');
          }
          else if (policydata.data.ref1 == 'HK') {
            let smartUploadURL = environment.smartUpload.url;
            this.policyService.getSmartUploadUrl({
              policyno: policydata.data.policyNumber,
              username: this.userService.getUserInfo().firstName
            }).subscribe(
              data => {
                if (data?.url && data.url != '') {
                  smartUploadURL = data.url;
                }
                window.location.href = smartUploadURL;
              },
              error => {
                window.location.href = smartUploadURL;
              }
            );

          }
          else {
            this.scriptService.pendoInit();
            let returnUrl = sessionStorage.getItem('returnUrl') || '/dashboard';

            let dwRequest = {
              productSourceSystem: "gw",
              sourceSystemIdentifierName: "PolicyNumber",
              sourceSystemIdentifier: policydata?.data?.policyNumber,
              country: "US",
              roleType: "string",
              productType: "string"
            }
            this.billingService.DWgetSummary(dwRequest).subscribe(
              data => {
                if (data?.data?.wallet) {
                  sessionStorage.setItem('dwDetails', JSON.stringify(data.data.wallet))
                }
                this.router.navigateByUrl(returnUrl);
              },
              error => {
                this.router.navigateByUrl(returnUrl);
              }
            );
          }
        }
      },
      error => this.errorOccurred = true
    )
  }

  onErrors() {
    localStorage.clear();
    sessionStorage.clear();

    this.msalService.logout({
      postLogoutRedirectUri: '/auth'
    });
  }
}

