import { Component, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EmailVerificationResponse } from './models/email-verification-response';
import { IVerificationInterface, emptyVerificationInterface } from '../../models/verification.interface';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'upsc-email-address-verification',
  templateUrl: './email-address-verification.component.html',
  styleUrls: ['./email-address-verification.component.scss']
})
export class EmailAddressVerificationComponent {
  @Input() email = "";
  @Input() style: 'happy-returns' | 'default';
  @Output() verifiedResponse = new EventEmitter<any>();

  confirmFormGroup: UntypedFormGroup;
  emailVerificationResponse: EmailVerificationResponse = {};

  @ViewChild("num1") field1: ElementRef;
  @ViewChild("num2") field2: ElementRef;
  @ViewChild("num3") field3: ElementRef;
  @ViewChild("num4") field4: ElementRef;
  @ViewChild("num5") field5: ElementRef;
  @ViewChild("num6") field6: ElementRef;

  verifyCodeError: boolean = false;

  public createVerificationInterface: IVerificationInterface = emptyVerificationInterface;
  verifyCodeInterface = { emailId: "", toVerify: "" };
  loading = false;

  constructor(
    private fb: UntypedFormBuilder,
    private accountService: AccountService
  ){ }

  ngOnInit(): void {
    this.confirmFormGroup = this.fb.group({
      firstDigit: ['', Validators.required],
      secondDigit: ['', Validators.required],
      thirdDigit: ['', Validators.required],
      fourthDigit: ['', Validators.required],
      fifthDigit: ['', Validators.required],
      sixthDigit: ['', Validators.required]
    });
  }

  //Handles paste event for verification code
  pasted() {
    if (this.field1.nativeElement.value.length > 1) {
      let digits = this.splitText();
      this.focusThis(digits)
    }
  }

  //Determines where the next focus should be for verification code
  focusThis(nextIndex: number, event?) {
    if (nextIndex == 2) {
      if (event && (event.key == "Backspace" || event.key == "Delete")) {
        this.field1.nativeElement.focus();
      }
      else {
        this.field2.nativeElement.focus();
      }
    } else if (nextIndex == 3) {
      if (event && (event.key == "Backspace" || event.key == "Delete")) {
        this.confirmFormGroup.get('secondDigit').setValue(' ');
        this.field1.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('secondDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('secondDigit').setValue(event.key);
        }
        this.field3.nativeElement.focus();
      }

    } else if (nextIndex == 4) {
      if (event && (event.key == "Backspace" || event.key == "Delete")) {
        this.confirmFormGroup.get('thirdDigit').setValue(' ');
        this.field2.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('thirdDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('thirdDigit').setValue(event.key);
        }
        this.field4.nativeElement.focus();
      }
    } else if (nextIndex == 5) {
      if (event && (event.key == "Backspace" || event.key == "Delete")) {
        this.confirmFormGroup.get('fourthDigit').setValue(' ');
        this.field3.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('fourthDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('fourthDigit').setValue(event.key);
        }
        this.field5.nativeElement.focus();
      }
    } else if (nextIndex == 6) {
      if (event && (event.key == "Backspace" || event.key == "Delete")) {
        this.confirmFormGroup.get('fifthDigit').setValue(' ');
        this.field4.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('fifthDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('fifthDigit').setValue(event.key);
        }
        this.field6.nativeElement.focus();
      }
    }
    else if (nextIndex >= 7) {
      if (event && (event.key == "Backspace" || event.key == "Delete")) {
        this.confirmFormGroup.get('sixthDigit').setValue(' ');
        this.field5.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('sixthDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('sixthDigit').setValue(event.key);
        }
        this.field6.nativeElement.focus();
      }
    }
  }

  //Splits up verification code if user tries to paste
  splitText() {
    var str = this.field1.nativeElement.value;
    var splitted = str.split("");
    this.confirmFormGroup.setValue({
      firstDigit: splitted[0],
      secondDigit: splitted[1] || '',
      thirdDigit: splitted[2] || '',
      fourthDigit: splitted[3] || '',
      fifthDigit: splitted[4] || '',
      sixthDigit: splitted[5] || ''
    })
    return splitted.length;
  }

  //Send verification code that user entered to api 
  checkVerificationCode() {
    this.loading = true;
    this.verifyCodeInterface.emailId = this.email;
    this.verifyCodeInterface.toVerify = this.confirmFormGroup.get('firstDigit').value + this.confirmFormGroup.get('secondDigit').value + this.confirmFormGroup.get('thirdDigit').value + this.confirmFormGroup.get('fourthDigit').value + this.confirmFormGroup.get('fifthDigit').value + this.confirmFormGroup.get('sixthDigit').value;
    this.accountService.verifyTupssCode(this.verifyCodeInterface).subscribe(
      data => {
        if ((data.message).toLowerCase() === 'verification code matched') {         
          //User is verified & received a token
          sessionStorage.setItem('access_token', data.accessToken);
          sessionStorage.setItem('happy-returns-email', JSON.stringify({ email: this.email }));
          this.emailVerificationResponse.isVerified = true;
          this.emailVerificationResponse.message = 'verification code matched';
          this.verifyCodeError = false;
          this.verifiedResponse.emit(this.emailVerificationResponse);
        } else {
          this.loading = false;
          this.verifyCodeError = true;
          this.emailVerificationResponse.isVerified = false;          
          this.emailVerificationResponse.message = (data.message).toLowerCase();
          this.verifiedResponse.emit(this.emailVerificationResponse);
        }
      }, error => {
        this.loading = false;
        if (error.error && error.error.message && error.error.message.toLowerCase() == 'verification code did not match') {
          this.emailVerificationResponse.isVerified = false;
          this.emailVerificationResponse.message = 'verification code did not match';
          this.verifyCodeError = true;
          this.verifiedResponse.emit(this.emailVerificationResponse);
        } else {
          this.emailVerificationResponse.isVerified = false;
          this.emailVerificationResponse.message = error.error.message.toLowerCase();
          this.verifyCodeError = true;
          this.verifiedResponse.emit(this.emailVerificationResponse);
        }
      }
    );
  }


  cancel() {
    this.loading = false;
    this.emailVerificationResponse.isVerified = false;
    this.emailVerificationResponse.message = 'cancel';
    this.verifiedResponse.emit(this.emailVerificationResponse);
  }

}
