import { UserService } from 'src/app/services/user.service';
import { Component, HostBinding, OnInit, TemplateRef, ViewChild, ChangeDetectorRef, HostListener } from '@angular/core';
import { PolicyService } from 'src/app/services/policy.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ContactUsComponent } from 'src/app/shared/dialogs/contact-us/contact-us.component';
import { UtilityService } from 'src/app/services/utility.service';
import { Router } from '@angular/router';
import { environment as ENV } from 'src/environments/environment';
import { environment } from 'src/environments/environment';
import { ClaimStats } from 'src/app/shared/models/claim.interface';
import { ClaimService } from 'src/app/services/claim.service';
import { TaggingService } from 'src/app/tags/tagging.service';
import { UserPermission } from 'src/app/shared/models/user-permission';
import { LoginService } from 'src/app/services/login.service';
import { AccountInfo } from 'src/app/shared/models/account-info.interface';
import { MatDialogConfig } from '@angular/material/dialog';
import { ContactUsPopupComponent } from '../contact-us-popup/contact-us-popup.component';
import { TooltipPosition } from '@angular/material/tooltip';
import { FormControl } from '@angular/forms';
import { DeliveryDefenseAgreementComponent } from '../../delivery-defense-agreement/delivery-defense-agreement.component';
import { DeliveryDefenseErrorComponent } from '../../delivery-defense-error/delivery-defense-error.component';
import { PolicyInfo } from 'src/app/shared/models/policy-info';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { SwitchPolicyEventService } from 'src/app/services/switch-policy-event.service';
import { PolicyEventService } from 'src/app/services/policy-event.service';
import { GuestLinkService } from 'src/app/services/guest-link.service';
import { Subscription } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { VisibilityPopupComponent } from 'src/app/pages/dd-visibility/dialog/visibility-popup/visibility-popup.component';

@Component({
  selector: 'upsc-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss']
})
export class TopNavigationComponent {
  @HostBinding('class') public hostClass = 'navigation';
  isAdjuster: boolean;
  isUspsUser: boolean;
  isGuestClaim: boolean;
  isGuestUser: boolean;
  showBilling: boolean;
  isHPGuestUser: boolean;
  popoverPosition$: any;
  hasInsights: boolean;
  hasSmartUpload: boolean;
  hasDeliveryDefense: boolean;
  isCanadaUser: boolean;
  isUkUser: boolean;
  isUsaUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;
  isDDUser: boolean = false;
  isDDClaimsFlow: boolean;
  hasDDVisibility: boolean;
  isAdminRole: boolean;
  isConnectPolicy: boolean;

  userDetails: AccountInfo;
  visibilityURL: string;
  Username: string;
  firstName: string;
  fName: string;
  claimStats: ClaimStats;
  showStats: boolean;
  showRLSMenuItems: boolean;
  isDDApplicable: boolean;
  isThreeContractNo: boolean;
  isCCPSelfServicePortal: boolean;
  canManageUsers: boolean = false;
  showRLSNav: boolean; //whether to show navigation by role level security
  loginName: string = '';
  canChangePolicy: boolean;
  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  position = new FormControl(this.positionOptions[0]);
  public userPermission: UserPermission = new UserPermission();
  messageCnt = "";
  policyDetails: any;
  visibilityDetails: any;
  isNickNameEdit: boolean = false;

  public nickNameFormGroup: UntypedFormGroup;
  userRoleID: string = "";
  billingWallet: boolean = false;
  disableAddPolicy: boolean = false;

  dwDetails;
  subscription: Subscription;

  policyInfoList: PolicyInfo[] = [];
  childPolicyList: string[] = [];

  constructor(private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private guestLinkService: GuestLinkService,
    private policyService: PolicyService,
    private utilityService: UtilityService,
    private claimService: ClaimService,
    private router: Router,
    private taggingService: TaggingService,
    private loginService: LoginService,
    public contactUsDialog: MatDialog,
    public dialog: MatDialog,
    public switchPolicyService: SwitchPolicyEventService,
    private policyEventService: PolicyEventService,
    private cdr: ChangeDetectorRef,
    private msalService: MsalService) {
    this.popoverPosition$ = this.utilityService.popoverPositionWatch();

  }

  ngAfterViewChecked() {
    if (this.policyInfoList?.length >= 4) {
      this.disableAddPolicy = true;
    }
    else {
      this.disableAddPolicy = false;
    }
    this.cdr.detectChanges();

  }


  public ngOnInit() {
    this.isUspsUser = this.userService.isUSPSUser();
    this.isAdjuster = this.userService.isUserAdjuster();
    this.isGuestClaim = this.userService.isGuestClaim();
    this.isHPGuestUser = this.userService.isHPGuestUser();
    this.isGuestUser = this.userService.isGuestUser();
    this.showBilling = this.policyService.isGWPolicy();
    this.hasInsights = this.policyService.hasInsights();
    this.hasDeliveryDefense = this.policyService.hasDeliveryDefense();
    this.showRLSMenuItems = !this.userService.isInternalUser() || !!this.loginService.getPolicyDetails()?.policyNumber;
    this.isDDApplicable = !this.policyService.isThreeHyphenContractNo() && !this.policyService.isTwoHyphenContractNo();
    this.isThreeContractNo = this.policyService.isThreeHyphenContractNo();
    this.canManageUsers = this.userService.getUserPermissions()?.isUserManagement && !this.policyService.isTUPSSContractNumber();
    //CCP Self Service portal check
    this.subscription = this.guestLinkService.ccpSSPCheck$.subscribe((data) => {
      let ccp_SSP_Details = JSON.parse(sessionStorage.getItem('ccpUniquePartnerName'));
      if (ccp_SSP_Details && ccp_SSP_Details.ccp_selfService_Check == true) {
        this.isCCPSelfServicePortal = true;
      }
    });
    this.isCanadaUser = this.userService.isCanadaUser();
    this.isUkUser = this.userService.isUkUser();
    this.isUsaUser = this.userService.isUsaUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.isFranceUser = this.userService.isFranceUser();
    this.isItalyUser = this.userService.isItalyUser();
    this.isDDUser = this.userService.isDeliveryDefenseUser();
    this.isDDClaimsFlow = this.userService.isDDClaimsFlow();
    this.isAdminRole = this.userService.isAdminRole();
    this.isConnectPolicy = this.policyService.isConnectPolicy();
    this.userRoleID = this.userService.getUserInfo().userRoleID;
    this.dwDetails = this.loginService.getDWDetails();

    this.subscription = this.policyEventService.nameData$.subscribe((data) => {
      this.getUserPolicyDetails();
    });

    if (this.dwDetails?.walletID) {
      this.billingWallet = true;
    } else {
      this.billingWallet = false;
    }

    let data = this.loginService.getPolicyDetails();
    if (data != undefined && data != null) {
      if ((this.loginService.getPolicyDetails().policyNumber) && (!this.isDDUser)) {
        this.claimService.getClaimStats(this.loginService.getPolicyDetails().policyNumber, '').subscribe(
          data => {
            this.claimStats = data;
            this.showStats = this.claimStats.claimsWithNewMessagesCnt > 0;
            this.messageCnt = "You have " + this.claimStats?.claimsWithNewMessagesCnt + " new messages!";
          }
        );
      }
    }

    this.nickNameFormGroup = this.formBuilder.group({
      nickNameFormControl: ['', [Validators.required, Validators.minLength(6)]]
    });


    this.userPermission = this.userService.getUserPermissions();

    this.showRLSNav = !this.userService.isInternalUser() || this.policyService.hasPolicyDetails();
    this.canChangePolicy = this.userService.isInternalUser();

    this.hasSmartUpload = this.policyService.hasSmartUpload() || this.isConnectPolicy;
    this.hasDDVisibility = this.isAdminRole && (this.hasInsights || this.policyService.hasShipVision() || this.isConnectPolicy);

    this.userDetails = this.userService.getUserInfo();
    this.Username = sessionStorage.getItem('userid');

    if (this.userDetails) {
      try {
        this.fName = this.userDetails.contactName.substring(0, 1);
        this.loginName = this.userDetails.contactName;
      }
      catch {
        this.fName = 'I';
        this.loginName = 'Internal User';
      }
    } else {
      this.loginService.removeLocalStorage(this.Username, this.userDetails?.objectUID).subscribe(result => { });
      this.router.navigate(['/login']);
      window.location.reload();
    }

    this.policyDetails = JSON.parse(sessionStorage.getItem('policyDetails'));
    if (this.policyDetails) { // this checks to see if DD agreement has already been accepted
      // Call startDeliveryDefense to reroute to DD
    }

    if ((!this.isGuestClaim) && (!this.isAdjuster) && (!this.isUspsUser) && (!this.isDDUser) && (this.isUsaUser) && (this.hasDDVisibility)) {
      this.VisibilityApiCall();
    }
  }


  getPolicyDetails(userId: any, groupId: any, shipperNumber: any) {
    let request = {
      userId: userId,
      groupId: groupId !== null ? groupId : 0,
      shipperNumber: shipperNumber !== null ? shipperNumber : '',
    };
    this.policyInfoList.splice(0, this.policyInfoList.length);
    let policyDetails = JSON.parse(sessionStorage.getItem('policyDetails'));
    this.policyService.getUserPolicyDetails(request).subscribe(
      response => {
        this.policyInfoList = [];
        this.childPolicyList = [];

        let policyInfo = new PolicyInfo();
        policyInfo.policyNumber = response.data.parentPolicy;
        policyInfo.productType = response.data.parentSubPolicyType !== null ? response.data.parentSubPolicyType : '';
        policyInfo.nickName = response.data.nickname;
        policyInfo.policyHoldersName = response.data.policyHoldersName;
        if (policyDetails != undefined && policyDetails != null) {
          if (policyDetails.policyNumber === response.data.parentPolicy) {
            policyInfo.isActive = true;
          }
        } else {
          policyInfo.isActive = false;
        }
        var parentPolicyExist = this.policyInfoList.find(x => x.policyNumber === response.data.parentPolicy);
        if (!parentPolicyExist) {
          this.policyInfoList.push(policyInfo);
        }

        if (response.data.childPolicies !== undefined && response.data.childPolicies !== null && response.data.childPolicies.length > 0) {
          response.data.childPolicies?.forEach(item => {
            policyInfo = new PolicyInfo();
            policyInfo.policyNumber = item.childPolicyNumber;
            policyInfo.productType = item.childSubPolicyType !== null ? item.childSubPolicyType : '';
            policyInfo.nickName = item.nickname;
            policyInfo.policyHoldersName = item.childHoldersName;
            if (policyDetails.policyNumber === item.childPolicyNumber) {
              policyInfo.isActive = true;
            }
            else {
              policyInfo.isActive = false;
            }
            var isPolicyExist = this.policyInfoList.find(x => x.policyNumber === item.childPolicyNumber);
            if (!isPolicyExist) {
              this.policyInfoList.push(policyInfo);
              this.childPolicyList.push(item.childPolicyNumber);
            }
            sessionStorage.setItem("PolicyCount", this.policyInfoList.length.toString());
            sessionStorage.setItem("UserPolicyDetails", JSON.stringify(this.policyInfoList));
            sessionStorage.setItem("childPoliciesEA", JSON.stringify(this.childPolicyList));
          });
        } else {
          sessionStorage.setItem("childPoliciesEA", JSON.stringify(this.childPolicyList));
        }
        sessionStorage.setItem("PolicyCount", this.policyInfoList.length.toString());
        sessionStorage.setItem("UserPolicyDetails", JSON.stringify(this.policyInfoList));
      },
      error => {
        console.log(error);
      }
    );
  }



  getChildPolicies() {
    var isPolicyList = sessionStorage.getItem("childPoliciesEA");
    if (isPolicyList !== null) {
      this.childPolicyList.splice(0, this.policyInfoList.length);
      this.childPolicyList = JSON.parse(sessionStorage.getItem("childPoliciesEA"));
      return this.childPolicyList;
    }
  }


  getUserPolicyDetails() {
    var isPolicyList = sessionStorage.getItem("UserPolicyDetails");
    if (isPolicyList === null || isPolicyList === undefined || isPolicyList === '0') {
      this.policyInfoList.splice(0, this.policyInfoList.length);
      let userId = this.userService.getUserInfo().userId;
      let groupID = this.userService.getUserInfo().groupID;
      let shipperNumber = this.userService.getUserInfo().groupID;
      this.getPolicyDetails(userId, groupID, shipperNumber);
    }
    else {
      this.policyInfoList.splice(0, this.policyInfoList.length);
      this.policyInfoList = JSON.parse(sessionStorage.getItem("UserPolicyDetails"));
    }
  }

  currentNickName = ''
  editNickName($event: any, nickName: string) {
    $event.stopPropagation();
    this.currentNickName = nickName;
    this.isNickNameEdit = true;
  }

  updateNickName($event: any, item: any) {
    $event.stopPropagation();
    let request = {
      userID: Number(this.userDetails.userId),
      policyNumber: item.policyNumber,
      policyNickname: item.nickName
    };
    this.policyService.updatePolicyNickname(request).subscribe(
      data => {
        if (data) {
          this.isNickNameEdit = false;
          sessionStorage.setItem("UserPolicyDetails", "0");
          this.getUserPolicyDetails();
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  closeNickName($event: any) {
    $event.stopPropagation();
    this.isNickNameEdit = false;
  }

  togglePolicy(policy: any) {
    this.switchPolicyService.togglePolicy(policy.policyNumber, Number(this.userDetails.userRoleID));
  }

  isPageInit: boolean = false;
  ngAfterContentInit() {
    this.isPageInit = true;
    try {
      this.fName = this.userDetails.contactName.substring(0, 1);
      this.loginName = this.userDetails.contactName;
    }
    catch {
      this.fName = 'I';
      this.loginName = 'Internal User';
    }
  }

  resetPolicy() {
    sessionStorage.removeItem('policyDetails');
    sessionStorage.removeItem('ccpUniquePartnerName');
    this.router.navigate(['internal']);
  }

  isCollapsed: boolean = true;
  dialogRef: any;
  contactusPopover: boolean = false;
  contactusPopoverCanada: boolean = false;
  isDDVisibilityMenu: boolean = false;
  isDDVisibilityPlan: boolean = false;
  visibilitySettings: any = {};

  // Listen for click events on the document
  // @HostListener('document:click', ['$event'])
  // onDocumentClick(event: MouseEvent): void {
  //   // If the click is outside the popup, hide it
  //   if (this.isClickOutsidePopup(event)) {
  //     this.isDDVisibilityMenu = false;
  //   }
  // }
  // Listen for scroll events on the document
  @HostListener('document:scroll', ['$event'])
  onDocumentScroll(event: Event): void {
    // Hide the popup when scrolling happens outside
    this.isDDVisibilityMenu = false;
  }

  // Function to check if the click happened outside the popup
  private isClickOutsidePopup(event: MouseEvent): boolean {
    const popupElement = document.getElementById('popupDiv');
    return popupElement && !popupElement.contains(event.target as Node);
  }

  //  // Function to toggle visibility of the popup (for testing)
  //  togglePopup(): void {
  //    this.isDDVisibilityMenu = !this.isDDVisibilityMenu;
  //  }

  VisibilityApiCall() {
    let request = {
      policyNumber: this.policyDetails?.policyNumber,
      action: "query"
    }
    this.policyService.ddVisibilityByAction(request).subscribe(
      data => {
        if (data) {
          this.visibilityDetails = data;
        }
      }
    );
  }

  showDdVisibilityMenu() {
    if (this.visibilityDetails.customerNo && this.visibilityDetails.subscription && this.visibilityDetails.contractNo) {
      this.isDDVisibilityMenu = true;
      sessionStorage.setItem('existingPlanDetails', JSON.stringify(this.visibilityDetails));
      this.visibilityURL = `https://skipum-${this.visibilityDetails.customerNo}-ddv-skipum-approuter.cfapps.${ENV.visibility.contaxEnvironment}.hana.ondemand.com/cp.portal/site#Visibility-OVP_DDVIS?sap-ui-app-id-hint=visibility-ovp-ddvis`;
    }
    else if (this.visibilityDetails.contractNo) {
      let config = new MatDialogConfig();
      config.autoFocus = false;
      config.width = '510px';
      this.visibilitySettings.action = "inProgress";
      this.visibilitySettings.subscription = "";
      sessionStorage.setItem('visibilitySettings', JSON.stringify(this.visibilitySettings));
      let dialogRef = this.dialog.open(VisibilityPopupComponent, this.visibilitySettings);
      dialogRef.afterClosed().subscribe(
        closed => {
        }
      );
    }
    else if (this.visibilityDetails.statusCode == "404" && this.visibilityDetails.message == "Contract not found.") {
      this.router.navigateByUrl('/visibility');
    }
  }


  handleClick() {
    this.isDDVisibilityMenu = false;
  }

  contactUs() {
    let contactusPopover = 'false';
    let contactusPopoverCanada = 'false';
    if ((this.isCanadaUser && this.isGuestUser) == true) {
      contactusPopover = 'true';
      contactusPopoverCanada = 'false';
    }
    else if (this.isCanadaUser == true) {
      contactusPopoverCanada = 'true';
      contactusPopover = 'false';
    }
    else {
      contactusPopover = 'true';
      contactusPopoverCanada = 'false';
    }

    sessionStorage.setItem("contactusPopover", contactusPopover);
    sessionStorage.setItem("contactusPopoverCanada", contactusPopoverCanada);

    let config = new MatDialogConfig();
    config.autoFocus = false;
    config.closeOnNavigation = true;
    config.width = this.isUsaUser ? '250px' : '500px';
    config.position = { right: '50px', top: '100px' }
    config.data = {
      contactusPopover: contactusPopover,
      contactusPopoverCanada: contactusPopoverCanada,
      name: 'Test Contact Us'
    }

    this.dialogRef = this.dialog
      .open(ContactUsPopupComponent, config)
      .afterClosed().subscribe(result => {

      });
  }


  contactUs1() {
    if ((this.isCanadaUser && this.isGuestUser) == true) {
      console.log('contactusPopover');
    }
    else if (this.isCanadaUser == true) {
      console.log('contactusPopoverCanada');
    }
    else {
      console.log('contactusPopover');
    }
  }

  logout(event) {
    this.taggingService.link({ link_name: 'log_out' });
    if (sessionStorage.getItem('idToken')) { //Azure B2C authenticated
      sessionStorage.clear();
      this.msalService.logoutRedirect({
        postLogoutRedirectUri: '/auth'
      });
    }
    else if (localStorage.getItem('id_token')) { //internal users
      sessionStorage.clear()
      localStorage.clear();
      window.location.href = environment.roleLevelSecurity.signin;
    }
    else {
      this.loginService.removeLocalStorage(this.Username, this.userDetails.objectUID).subscribe(result => { });
      let locale = null;
      if (sessionStorage.getItem('locale')?.match(/^(?!(us))[a-z]{2}[\-_][a-z]{2}$/i)) {
        locale = sessionStorage.getItem('locale');
      }
      if (locale) {
        this.router.navigateByUrl(`/login/${locale}`);
      }
      else {
        this.router.navigateByUrl(`/login`);
      }
    }
  }

  addPolicy() {
    this.router.navigate(['/newpolicy']);
  }

  // openShipVision(evt) {
  //   if (JSON.parse(sessionStorage.getItem('policyDetails')).shipVision?.startsWith('https://')) {
  //     window.open(JSON.parse(sessionStorage.getItem('policyDetails')).shipVision);
  //   } else {
  //     this.router.navigate(['/visibility']);
  //   }
  // }

  public ngOnDestroy() {
    sessionStorage.removeItem('retainState');
    sessionStorage.removeItem('isPostBack');
    sessionStorage.removeItem('pageId');
    sessionStorage.removeItem('isadvanced');
    this.subscription.unsubscribe();
  }

  public openContactUsDialog(): void {
    this.taggingService.link({
      link_name: 'contact_us_header_tab'
    });
    const dialogRef = this.contactUsDialog.open(ContactUsComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  startSmartUpload(event) {
    let smartUploadURL = environment.smartUpload.url;

    this.policyService.getSmartUploadUrl({
      policyno: this.loginService.getPolicyDetails().policyNumber,
      username: this.userService.getUserInfo().firstName
    }).subscribe(
      data => {
        if (data.url && data.url != '') {
          smartUploadURL = data.url;
        }
        window.open(smartUploadURL);
      },
      error => {
        window.open(smartUploadURL);
      }
    );
  }

  validateDeliveryDefense(event) {
    let policyDetails = JSON.parse(sessionStorage.getItem('policyDetails'));
    if (policyDetails && policyDetails.ref1 == 'DD' && policyDetails.ref2) { // this checks to see if DD agreement has already been accepted
      // Call startDeliveryDefense to reroute to DD
      this.startDeliveryDefense(event);
    } 
    else if (policyDetails && policyDetails.ref1 == 'DD API' && policyDetails.ref2_1) {
      // These are "DD API" users, still re-route to DD, but call a different API to get the redirection link.
      this.startDDAPIUser();
    } 
    else { // this means ref2 was null or non-existent, which signals for us to display the pop-up
      const dialogRef = this.dialog.open(DeliveryDefenseAgreementComponent);
    }
  }

  // call startDeliveryDefense only if they've accepted terms and conditions- which is indicated by the presence of the ref2 field in policyDetails
  startDeliveryDefense(event) {
    this.policyService.getDeliveryDefenseUrl({
      emailId: this.userService.getUserInfo().emailAddress,
      contractNumber: JSON.parse(sessionStorage.getItem('policyDetails')).ref2

    }).subscribe(
      res => {
        if (res.data.url && res.data.url != '') {
          window.open(res.data.url);
        } else {
          let matDialog2 = this.dialog.open(DeliveryDefenseErrorComponent);
        }
      },
      error => {
        let matDialog = this.dialog.closeAll();
        let matDialog2 = this.dialog.open(DeliveryDefenseErrorComponent);
      }
    );
  }

  startDDAPIUser() {
    this.policyService.getDDAPIUrl({
      emailId: this.userService.getUserInfo().emailAddress,
      contractNumber: JSON.parse(sessionStorage.getItem('policyDetails')).ref2_1
    }).subscribe(
      res => {
        if (res.data.url && res.data.url != '') {
          window.open(res.data.url);
        } else {
          let matDialog2 = this.dialog.open(DeliveryDefenseErrorComponent);
        }
      },
      error => {
        let matDialog = this.dialog.closeAll();
        let matDialog2 = this.dialog.open(DeliveryDefenseErrorComponent);
      }
    )
  }

  startDeliveryDefense1(event) {
    let deliveryDefenseURL = environment.deliveryDefense.url;

    this.policyService.getDeliveryDefenseUrl({
      emailId: this.userService.getUserInfo().emailAddress,
    }).subscribe(
      res => {
        if (res.data.url && res.data.url != '') {
          deliveryDefenseURL = res.data.url;
        }
        window.open(deliveryDefenseURL);
      },
      error => {
        window.open(deliveryDefenseURL);
      }
    );
  }



  gotoMessages(event: any) {
    this.router.navigate(['claims', 'message-list']);
  }

  tagging(type) {
    if (type == 'dashboard') {
      this.taggingService.link({ link_name: 'dashboard_header_tab' });
    } else if (type == 'policies') {
      this.taggingService.link({ link_name: 'policies_header_tab' });
    } else if (type == 'claims') {
      this.taggingService.link({ link_name: 'claims_header_tab' });
    } else if (type == 'billing') {
      this.taggingService.link({ link_name: 'billing_header_tab' });
    }
  }

}
